'use client';

import { CookieStore } from '@exchanger/common'
import { AuthService } from '@/service/auth';
import { useMutation } from '@tanstack/react-query';
import { useCookies } from 'next-client-cookies';
import { useRouter } from 'next/navigation';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { routerLink } from '@/constant/router-link.const'

interface AuthUser {
  readonly clientId: string;
  readonly email: string;
  readonly twoFactorStatus: boolean;
}

type AuthContextType = {
  isAuth: boolean;
  getUser: AuthUser | undefined;
  signIn: () => void;
  logOut: () => void;
  refreshUser: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const cookieStore = useCookies();
  const { mutate: mutateLogout } = useMutation({
    mutationKey: ['logout'],
    mutationFn: () => AuthService.logout(),
  });

  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [getUser, setUser] = useState<AuthUser | undefined>(undefined);

  useEffect(() => {
    refreshUser()
  }, []);

  const refreshUser = () => {
    const userCookie = cookieStore.get(CookieStore.user) as string;
    if (userCookie) {
      setIsAuth(true);
      setUser(JSON.parse(userCookie));
    }
  }
  const signIn = () => {
    const userCookie = cookieStore.get(CookieStore.user) as string;
    if (userCookie) {
      cookieStore.remove(CookieStore.verifyToken)
      cookieStore.remove(CookieStore.referralCode)
      const user = JSON.parse(userCookie) as AuthUser;
      setIsAuth(true);
      setUser(user);
      router.push(routerLink.partner);
    }
  };

  const logOut = () => {
    setIsAuth(false);
    cookieStore.remove(CookieStore.user)
    cookieStore.remove(CookieStore.clientId)
    cookieStore.remove(CookieStore.referralCode)
    cookieStore.remove(CookieStore.email)
    cookieStore.remove(CookieStore.accessToken)
    cookieStore.remove(CookieStore.refreshToken)
    mutateLogout(); 
    router.push(routerLink.home);
  };


  return (
    <AuthContext.Provider value={{ isAuth, getUser, signIn, logOut, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
