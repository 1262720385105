'use client'
import { routerLink } from '@/constant/router-link.const'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const Cookie = () => {
	const { t } = useTranslation();
	const router = useRouter()
	const [showPolicy, setShowPolicy] = useState(true)
	
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const cookie = localStorage.getItem('cookie')
			if (cookie) {
				setShowPolicy(cookie !== 'false')
			}
		}
	}, [])
	
	const hiddenPolicy = () => {
		if (typeof window !== 'undefined') {
			localStorage.setItem('cookie', 'false')
		}
		setShowPolicy(false)
	}

	return (
		<>
			{
				showPolicy && (
					<div className='z-[9999] w-full left-[32px] bottom-[32px] fixed'>
						<div className='flex flex-row items-center gap-2 bg-black max-w-[300px] md-1:max-w-[400px] border border-[#212020] p-4 shadow-gradient-white'>
							<span className='text-white'>{t('cookie:title')}</span>
							<span onClick={() => router.push(routerLink.docs.privacyPolicy)} className='text-white cursor-pointer underline'>{t('cookie:link')}</span>
							<div className="border p-1 rounded-full hover:bg-white">
								<span onClick={() => hiddenPolicy()} className="text-white cursor-pointer p-3 hover:text-black">
									Ok
								</span>
							</div>
						</div>
					</div>
				)
			}
		</>
	)
}

export default Cookie;
