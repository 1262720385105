import { AuthJwtAccessDTO, AuthJwtVerifySignInDTO, AuthSignInDTO, AuthSignUpDTO, AuthVerifyCodeDTO } from '@exchanger/common'
import { serviceFetch } from '../fetch'

export const AuthService = {

	async logout(){
		return await serviceFetch<null, Boolean>({
			path: '/auth/logout',
    		method: 'POST'
		});
	},

	async signIn(data: AuthSignInDTO){
		return await serviceFetch<AuthSignInDTO, AuthJwtVerifySignInDTO | AuthJwtAccessDTO>({
    		path: '/auth/signin',
    		method: 'POST',
			data: data,
		});
	},

	async signUp(data: AuthSignUpDTO){
		return await serviceFetch<AuthSignUpDTO, AuthJwtVerifySignInDTO>({
    		path: '/auth/signup',
    		method: 'POST',
			data: data,
		});
	},

	async verify(data: AuthVerifyCodeDTO){
		return await serviceFetch<AuthVerifyCodeDTO, AuthJwtAccessDTO>({
			path: '/auth/verify',
    		method: 'POST',
			data: { code: data.code },
		})
	},

	async resendEmailCode(){
		return await serviceFetch<null, AuthJwtVerifySignInDTO>({
			path: '/auth/verify/resend-email-code',
    		method: 'POST',
		})
	}

}